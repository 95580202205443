<template>
  <div class="annex">
    <el-card>
      <div slot="header" class="annex-text">
        <span class="clearfix">项目列表信息</span>
      </div>
      <el-table :data="businessProjectList" max-height="600" border style="width: 100%">
        <el-table-column align="center" type="index" width="50" label="序号"></el-table-column>
        <el-table-column
          align="center"
          prop="projectName"
          label="项目名称"
          :show-overflow-tooltip="false"
          min-width="400"
        ></el-table-column>
        <el-table-column align="center" prop="theirDeptName" label="所属部门"></el-table-column>
        <el-table-column
          align="center"
          prop="deptClassifyManagerName"
          label="项目经理"
        ></el-table-column>
        <el-table-column prop="projectPredictStartDate" align="center" label="项目预计开始时间">
          <template slot-scope="scope">
            {{ scope.row.projectPredictStartDate | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column prop="projectPredictEndDate" align="center" label="项目预计结束时间">
          <template slot-scope="scope">
            {{ scope.row.projectPredictEndDate | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="status" label="项目状态">
          <template slot-scope="scope">
            {{ scope.row.status | dict(projectStatus) }}
          </template>
        </el-table-column>
        <el-table-column
          label="查看项目"
          width="160"
          align="center"
          v-if="permission(['BUSINESS_RELEVANCE_PROJECT'])"
        >
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click.prevent="viewerContract(scope.row)"
              >详情</el-button
            >
            <el-button size="mini" type="success" @click="leaveFor(scope.row)">前往</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog
      title="项目基本详情"
      v-if="ProjectEditDialogVisible"
      :visible.sync="ProjectEditDialogVisible"
      width="80%"
      append-to-body
      :before-close="handleCloseFn"
    >
      <div class="el-dialog-div">
        <ProjectEdit :ProjectEditId="ProjectEditId" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { initTreeData } from '@/util/common'
import { mapGetters, mapState } from 'vuex'
import { number } from 'echarts'
export default {
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
    ProjectEdit: () => import('@/views/agreement/ProjectEdit.vue'),
  },
  props: {},
  data() {
    return {
      projectStatus: [],
      ProjectEditDialogVisible: false,
      ProjectEditId: null,
    }
  },
  computed: {
    ...mapState({
      originalForm: state => state.project.originalForm,
      businessProjectList: state => state.business.businessProjectList,
    }),
  },
  created() {
    this.$api.dict
      .listSysDictData('PROJECT_STATUS', true)
      .then(res => {
        this.projectStatus = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  filters: {
    thousands(a) {
      if (a == null || a == '' || a == undefined) {
        return 0
      } else {
        a = Number(a).toLocaleString()
        return a
      }
    },
  },
  watch: {},
  methods: {
    // 前往项目
    async leaveFor(row) {
      const res = await this.$api.project.listProject({ id: row.id })
      if (res.data) {
        const obj = {
          operatingButton: res.data.records[0].operatingButton,
          id: res.data.records[0].id,
          status: res.data.records[0].status,
          itMonitoring: res.data.records[0].itMonitoring,
          isHaveChild: res.data.records[0].isHaveChild,
          isViewMonitoring: res.data.records[0].isViewMonitoring,
          performanceStatus: res.data.records[0].performanceStatus,
          postAttribute: res.data.records[0].postAttribute,
          staffAttribute: res.data.records[0].staffAttribute,
          residualWorkRatioContent: res.data.records[0].residualWorkRatioContent,
        }
        const { href } = this.$router.resolve({
          path: '/project/ProjectList',
          query: {
            data: JSON.stringify(obj),
          },
        })
        window.open(href, '_blank')
      }
    },
    viewerContract(row) {
      this.ProjectEditId = row.id
      this.ProjectEditDialogVisible = true
    },
    handleCloseFn() {
      this.ProjectEditId = null
      this.ProjectEditDialogVisible = false
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.annex {
  /deep/.el-card {
    //width:50%;
    .annex-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    + .el-card {
      margin-top: 20px;
    }
    .el-card__header {
      padding: 10px 15px;
      font-size: 16px;
      font-weight: bold;
    }
    .el-table {
      height: auto;
    }
  }
}
.el-dialog-div {
  height: 70vh;
  overflow: auto;
}
</style>
